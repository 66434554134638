@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-size-base: 18px;
}

body {
  margin: 0;
  font-family: 'Your Custom Font', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-base);
}

.circle-gallery-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
}

.circle-wrapper {
  perspective: 1000px;
  position: absolute;
}

.circle-container {
  position: relative;
  transform-style: preserve-3d;
  animation: rotate linear infinite;
}

.circle-block {
  position: absolute;
  transform-style: preserve-3d;
}

.circle-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: center;
  overflow: hidden;
  border-radius: 5px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes rotate {
  from { transform: rotateY(0deg); }
  to { transform: rotateY(360deg); }
}

code {
  font-family: 'Your Custom Monospace Font', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.special-text {
  font-family: 'Your Special Font', 'Helvetica Neue', Arial, sans-serif;
}

h2 {
  font-size: clamp(1.2rem, 5vw, 1.5rem);
}

.applicant-card {
  @apply w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 p-4;
}

.applicant-card img {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 1800px;
}

.applicant-card-content {
  @apply p-4 md:p-6 lg:p-8;
}

.custom-switch {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 34px;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.custom-switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.custom-switch input:checked + .slider {
  background-color: #2196F3;
}

.custom-switch input:checked + .slider:before {
  transform: translateX(calc(100% - 30px));
}

.custom-switch .slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: white;
  text-transform: uppercase;
}

.status-badge {
  height: 34px;
  line-height: 34px;
  padding: 0 !important;
  font-weight: bold;
  text-transform: uppercase;
}

.expand-button {
  @apply w-full py-3 text-lg bg-gray-200 hover:bg-gray-300 transition-colors;
}

.applicant-grid {
  @apply flex flex-wrap -mx-4;
}

@media (max-width: 1799px) {
  .applicant-card {
    @apply xl:w-1/5;
  }
}

@media (max-width: 1499px) {
  .applicant-card {
    @apply lg:w-1/4;
  }
}

@media (max-width: 1199px) {
  .applicant-card {
    @apply md:w-1/3;
  }
}

@media (max-width: 899px) {
  .applicant-card {
    @apply sm:w-1/2;
  }
}

@media (max-width: 599px) {
  .applicant-card {
    @apply w-full;
  }
}
/* ... (previous styles remain the same) ... */

.thumbnail-container {
  padding: 1rem;
  background-color: #f3f4f6;
}

.thumbnail {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 4px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Add smooth transitions to buttons */
button {
  transition: background-color 0.3s ease;
}

/* Custom scrollbar for the file list */
ul {
  max-height: 150px; /* Set a max height for better layout */
  overflow-y: auto; /* Scrollbar for long file lists */
}

ul::-webkit-scrollbar {
  width: 8px;
}

ul::-webkit-scrollbar-thumb {
  background-color: #555; /* Dark gray */
  border-radius: 4px;
}

ul::-webkit-scrollbar-thumb:hover {
  background-color: #888; /* Lighter gray on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .app-container {
    padding: 10px;
  }
}

/* File input hover effect */
.border-dashed:hover {
  border-color: #888; /* Lighter gray on hover */
}

/* File list styling for better visibility */
ul li {
  padding: 4px 0;
  color: #ccc;
}
